import React, { useEffect } from 'react'
import Layout from '../Layout/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FETCH_CATEGORIES } from 'src/redux/categoriesReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducer } from 'src/redux/rootReducer'
import { Link } from 'react-router-dom'

const CategoriesScreen = (): JSX.Element => {
  const dispatch = useDispatch()
  const categories = useSelector((state: RootReducer) => state.categoriesReducer.categories)

  useEffect(() => {
    dispatch(FETCH_CATEGORIES())
  }, [dispatch])
  return (
    <Layout withFilter>
      <div className="p-3">
        <h1 className="text-24 ml-6">Kategorien</h1>
        <div className="h-3" />
        <div className="bg-white rounded-sm w-full overflow-hidden">
          <div className="border-b-4 border-orange" />
          <div className="p-3">
            <table className="w-full text-14">
              <tr>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">id</td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">Name</td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold">Preisverhältnis</td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold"></td>
                <td className="p-2 border border-[#f4f4f4] text-16 font-semibold"></td>
              </tr>
              {categories.map((category, idx) => (
                <tr key={category.id} className={`${idx % 2 === 0 ? 'bg-grayBackground' : 'bg-white'}`}>
                  <td className="p-2 border border-[#f4f4f4]">{category.id}</td>
                  <td className="p-2 border border-[#f4f4f4]">{category.name}</td>
                  <td className="p-2 border border-[#f4f4f4]">{category.price_ratio}</td>
                  <td className="p-2 border border-[#f4f4f4]">
                    <Link
                      to={`/categories/${category.id}/edit`}
                      className="flex-1 justify-center flex items-center cursor-pointer">
                      <FontAwesomeIcon icon={faEdit} />
                    </Link>
                  </td>
                  <td className="p-2 border border-[#f4f4f4]">
                    <div className="flex-1 justify-center flex items-center cursor-pointer">
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
        <div className="h-4" />
        <div className="flex justify-end">
          <Link to="/categories/new" className="bg-orange text-white px-4 py-2 rounded-md">
            Neue Kategorie
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default CategoriesScreen
