import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SIGN_OUT } from 'src/redux/authReducer'
import Filter from '../atoms/Filter'
import { RootReducer } from 'src/redux/rootReducer'

interface Props {
  withSearch?: boolean
  onMenuClick: () => void
}

const Header = ({ withSearch = false, onMenuClick }: Props): JSX.Element => {
  const dispatch = useDispatch()
  const title = useSelector((state: RootReducer) => state.headerReducer.title)
  return (
    <div className="bg-blue flex min-h-14 items-center">
      <div onClick={onMenuClick} className="p-4 cursor-pointer hover:bg-darkBlue">
        <FontAwesomeIcon icon={faBars} size="1x" color="white" />
      </div>
      <div className="flex-1">
        <h1 className="text-white text-20 font-semibold">{title}</h1>
      </div>
      <a className="p-4 px-2 text-white hover:bg-darkBlue" href="/users/edit">
        Ändere das Passwort
      </a>
      <button
        onClick={() => {
          dispatch(SIGN_OUT())
        }}
        className="p-4 px-2 text-white hover:bg-darkBlue">
        Ausloggen
      </button>
      {withSearch && <Filter />}
    </div>
  )
}
export default Header
