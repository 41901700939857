import React from 'react'
import { Form } from 'react-final-form'
import { Invoice } from 'src/types'
import CustomerDataFrom from '../molecules/CustomerDataFrom'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import InvoicePositionForm from '../molecules/InvoicePositionForm'
import { useDispatch } from 'react-redux'
import { UPDATE_INVOICE } from 'src/redux/invoiceReducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

interface EditInvoiceFormProps {
  invoice: Invoice
  isLoading: boolean
}

const EditInvoiceForm = ({ invoice, isLoading }: EditInvoiceFormProps): JSX.Element => {
  const dispatch = useDispatch()
  const initialValues = {
    invoice_positions: invoice.invoice_positions.map((position) => ({
      description: position.description,
      zip_from: position.zip_from,
      zip_to: position.zip_to,
      price_gross: position.price_gross,
      order_id: position.order_id
    }))
  }

  const customerDataValues = {
    invoice_number: invoice.invoice_number,
    company_name: invoice.company_name,
    street: invoice.street,
    street_number: invoice.street_number,
    zip: invoice.zip,
    city: invoice.city,
    without_tax: false,
    payment_method: invoice.payment_method ?? 'cash',
    transferred_on: invoice.transferred_on ?? '',
    execution_date: null,
    tax_number: null
  }

  return (
    <div>
      <Form
        initialValues={{ ...initialValues, ...customerDataValues }}
        mutators={{ ...arrayMutators }}
        onSubmit={(values) => {
          dispatch(UPDATE_INVOICE({ id: invoice.id, values }))
        }}
        render={({ handleSubmit }) => (
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          <form onSubmit={handleSubmit}>
            <FieldArray name="invoice_positions">
              {({ fields }) => (
                <div>
                  {fields.map((name, index) => (
                    <InvoicePositionForm
                      key={name}
                      name={name}
                      index={index}
                      onRemoveClick={() => {
                        const confirm = window.confirm('Wollen Sie diese Position wirklich löschen?')
                        if (confirm) {
                          fields.remove(index)
                        }
                      }}
                    />
                  ))}
                  <button
                    type="button"
                    className="bg-orange text-14 py-2 px-10 text-center rounded-md cursor-pointer text-white"
                    onClick={() => {
                      fields.push({ description: '', zip_from: '', zip_to: '', price_net: 0 })
                    }}>
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
              )}
            </FieldArray>
            <CustomerDataFrom type="edit" />

            <div className="h-3" />
            <button
              disabled={isLoading}
              type="submit"
              className="bg-orange text-14 py-2 px-10 text-center rounded-md cursor-pointer disabled:cursor-not-allowed">
              Aktualisieren
            </button>
          </form>
        )}
      />
    </div>
  )
}

export default EditInvoiceForm
