import { all } from 'redux-saga/effects'
import SignInSaga from './SignInSaga'
import FetchUserSaga from './FetchUserSaga'
import SignOutSaga from './SignOutSaga'
import FetchUsersSaga from './FetchUsersSaga'
import DeleteUserSaga from './DeleteUserSaga'
import CreateUserSaga from './CreateUserSaga'
import FetchEmailTemplatesSaga from './FetchEmailTemplatesSaga'
import FetchEmailTemplateSaga from './FetchEmailTemplateSaga'
import UpdateEmailTemplatesSaga from './UpdateEmailTemplatesSaga'
import FetchBatchInvoiceCustomersSaga from './FetchBatchInvoiceCustomersSaga'
import CreateBatchInvoiceCustomerSaga from './CreateBatchInvoiceCustomerSaga'
import FetchBatchInvoiceCustomerSaga from './FetchBatchInvoiceCustomerSaga'
import UpdateBatchInvoiceCustomerSaga from './UpdateBatchInvoiceCustomerSaga'
import FetchOrderSaga from './FetchOrderSaga'
import CreateInvoicePositionSaga from './CreateInvoicePositionSaga'
import FetchInvoicePositionsSaga from './FetchInvoicePositionsSaga'
import CreateInvoiceFromInvoicePositionsSaga from './CreateInvoiceFromInvoicePositionsSaga'
import FetchInvoicesSaga from './FetchInvoicesSaga'
import CreateInvoiceReportSaga from './CreateInvoiceReportSaga'
import GenereCSVInvoiceReportSaga from './GenereCSVInvoiceReportSaga'
import FetchInvoiceSaga from './FetchInvoiceSaga'
import FetchPersonalizedEmailTemplateSaga from './FetchPersonalizedEmailTemplateSaga'
import SendEmailSaga from './SendEmailSaga'
import CreateInvoiceSaga from './CreateInvoiceSaga'
import UpdateInvoiceSaga from './UpdateInvoiceSaga'
import FetchEmailsSaga from './FetchEmailsSaga'
import UpdateOrderSaga from './UpdateOrderSaga'
import ResetOrderSaga from './ResetOrderSaga'
import FetchOrdersSaga from './FetchOrdersSaga'
import FetchRoutesSaga from './FetchRoutesSaga'
import FetchStatisticsSaga from './FetchStatisticsSaga'
import ArchiveRouteSaga from './ArchiveRouteSaga'
import FetchOrdersForRoutePlaningSaga from './FetchOrdersForRoutePlaningSaga'
import FetchDirectionsSaga from './FetchDirectionsSaga'
import UpdatePointsSaga from './UpdatePointsSaga'
import CreateOrUpdateRouteSaga from './CreateOrUpdateRouteSaga'
import FetchRouteSaga from './FetchRouteSaga'
import FetchRouteOrdersSaga from './FetchRouteOrdersSaga'
import FetchCategoriesSaga from './FetchCategoriesSaga'
import CreateCategorySaga from './CreateCategorySaga'
import UpdateCategorySaga from './UpdateCategorySaga'
import UpdateUserSaga from './UpdateUserSaga'

export function* rootSaga(): Generator {
  yield all([
    SignInSaga(),
    FetchUserSaga(),
    SignOutSaga(),
    FetchUsersSaga(),
    DeleteUserSaga(),
    CreateUserSaga(),
    FetchEmailTemplatesSaga(),
    FetchEmailTemplateSaga(),
    UpdateEmailTemplatesSaga(),
    FetchBatchInvoiceCustomersSaga(),
    CreateBatchInvoiceCustomerSaga(),
    FetchBatchInvoiceCustomerSaga(),
    UpdateBatchInvoiceCustomerSaga(),
    FetchOrderSaga(),
    CreateInvoicePositionSaga(),
    FetchInvoicePositionsSaga(),
    CreateInvoiceFromInvoicePositionsSaga(),
    FetchInvoicesSaga(),
    CreateInvoiceReportSaga(),
    GenereCSVInvoiceReportSaga(),
    FetchInvoiceSaga(),
    FetchPersonalizedEmailTemplateSaga(),
    SendEmailSaga(),
    CreateInvoiceSaga(),
    UpdateInvoiceSaga(),
    UpdateOrderSaga(),
    FetchEmailsSaga(),
    ResetOrderSaga(),
    FetchOrdersSaga(),
    FetchRoutesSaga(),
    FetchStatisticsSaga(),
    ArchiveRouteSaga(),
    FetchOrdersForRoutePlaningSaga(),
    FetchDirectionsSaga(),
    UpdatePointsSaga(),
    CreateOrUpdateRouteSaga(),
    FetchRouteSaga(),
    FetchRouteOrdersSaga(),
    FetchCategoriesSaga(),
    CreateCategorySaga(),
    UpdateCategorySaga(),
    UpdateUserSaga()
  ])
}
