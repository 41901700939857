import { combineReducers, Reducer } from 'redux'
import authReducer, { AuthReducer } from './authReducer'
import { UserReducer, userReducer } from './userReducer'
import { UsersReducer, usersReducer } from './usersReducer'
import { EmailTemplatesReducer, emailTemplatesReducer } from './emailTemplatesReducer'
import { EmailTemplateReducer, emailTemplateReducer } from './emailTemplateReducer'
import { BatchInvoiceCustomersReducer, batchInvoiceCustomersReducer } from './batchInvoiceCustomersReducer'
import { BatchInvoiceCustomerReducer, batchInvoiceCustomerReducer } from './batchInvoiceCustomerReducer'
import { OrderReducer, orderReducer } from './orderReducer'
import { InvoicesReducer, invoicesReducer } from './invoicesReducer'
import { InvoiceReducer, invoiceReducer } from './invoiceReducer'
import { EmailModalReducer, emailModalReducer } from './emailModalReducer'
import { PersonalizedEmailTemplateReducer, personalizedEmailTemplateReducer } from './personalizedEmailTemplateReducer'
import { EmailsReducer, emailsReducer } from './emailsReducer'
import { HeaderReducer, headerReducer } from './headerReducer'
import { OrdersReducer, ordersReducer } from './ordersReducer'
import { StatisticsReducer, statisticsReducer } from './statisticsReducer'
import { RoutesReducer, routesReducer } from './routesReducer'
import { RouteReducer, routeReducer } from './routeReducer'
import { RouteOrdersReducer, routeOrdersReducer } from './routeOrders'
import { CategoriesReducer, categoriesReducer } from './categoriesReducer'

export interface RootReducer {
  authReducer: AuthReducer
  userReducer: UserReducer
  usersReducer: UsersReducer
  emailTemplatesReducer: EmailTemplatesReducer
  emailTemplateReducer: EmailTemplateReducer
  batchInvoiceCustomersReducer: BatchInvoiceCustomersReducer
  batchInvoiceCustomerReducer: BatchInvoiceCustomerReducer
  orderReducer: OrderReducer
  invoicesReducer: InvoicesReducer
  invoiceReducer: InvoiceReducer
  emailModalReducer: EmailModalReducer
  personalizedEmailTemplateReducer: PersonalizedEmailTemplateReducer
  emailsReducer: EmailsReducer
  headerReducer: HeaderReducer
  ordersReducer: OrdersReducer
  statisticsReducer: StatisticsReducer
  routesReducer: RoutesReducer
  routeReducer: RouteReducer
  routeOrdersReducer: RouteOrdersReducer
  categoriesReducer: CategoriesReducer
}
const rootReducer: Reducer<RootReducer> = combineReducers({
  authReducer,
  userReducer,
  usersReducer,
  emailTemplatesReducer,
  emailTemplateReducer,
  batchInvoiceCustomersReducer,
  batchInvoiceCustomerReducer,
  orderReducer,
  invoicesReducer,
  invoiceReducer,
  emailModalReducer,
  personalizedEmailTemplateReducer,
  emailsReducer,
  ordersReducer,
  headerReducer,
  statisticsReducer,
  routesReducer,
  routeReducer,
  routeOrdersReducer,
  categoriesReducer
})

export default rootReducer
