import React, { useMemo } from 'react'
import { ADD_POINT, REMOVE_POINT } from 'src/redux/routeReducer'
import { useDispatch, useSelector } from 'react-redux'
import { Address } from 'src/types'
import WhatsAppCall from './WhatsAppCall'
import { RootReducer } from 'src/redux/rootReducer'
interface Props {
  isPointAdded: (id: number) => boolean
  address: Address
  orderNumber: string
  bikeModel: string
  type: 'from' | 'to'
  ordererName: string
}

const AddressCel = ({ isPointAdded, address, orderNumber, bikeModel, type, ordererName }: Props): JSX.Element => {
  const isPointAddedToRoute = useMemo(() => isPointAdded(address.id), [isPointAdded, address.id])
  const dispatch = useDispatch()
  const currentRouteId = useSelector((state: RootReducer) => state.routeReducer.routeId)

  const textColor = useMemo(() => {
    if (address.route === null || address.route?.id === currentRouteId) {
      if (isPointAddedToRoute) {
        return type === 'from' ? 'red' : 'green'
      }
      return ''
    }
    return '#888888'
  }, [address.route, currentRouteId, isPointAddedToRoute, type])

  return (
    <td
      style={{
        backgroundColor: isPointAddedToRoute ? '#eeeeee' : 'transparent',
        cursor: address.route === null || address.route?.id === currentRouteId ? 'pointer' : 'not-allowed',
        color: textColor
      }}
      onClick={() => {
        if (address.route !== null && address.route?.id !== currentRouteId) {
          return
        }
        if (isPointAddedToRoute) {
          dispatch(REMOVE_POINT({ address_id: address.id }))
        } else {
          dispatch(
            ADD_POINT({
              ...address.point,
              address_id: address.id,
              orderNumber,
              zip: address.zip,
              phone: address.first_phone ?? '',
              name: ordererName ?? '',
              id: address.id,
              transportType: address.transport_type,
              bikeModel
            })
          )
        }
      }}
      className="p-2 border cursor-pointer border-[#f4f4f4] relative overflow-hidden">
      {address.zip}
      <div className="inline-block ml-2">
        <WhatsAppCall phone={address.first_phone} />
      </div>
    </td>
  )
}

export default AddressCel
