/* eslint-disable @typescript-eslint/ban-types */
import { createReducer, createAction, PayloadAction } from '@reduxjs/toolkit'
import { Category } from '../types/index'

export interface CategoriesReducer {
  categories: Category[]
}

const initialState: CategoriesReducer = {
  categories: []
}

export const FETCH_CATEGORIES = createAction('categories_reducer/fetch_categories')
export const FETCH_CATEGORIES_SUCCESS = createAction<Category[]>('categories_reducer/fetch_categories_success')
export const FETCH_CATEGORIES_FAIL = createAction('categories_reducer/fetch_categories_fail')

interface CreteCategoryPayload {
  name: string
  priceRatio: number
}
export type CreateCategoryActionType = PayloadAction<CreteCategoryPayload>
export const CREATE_CATEGORY = createAction<CreteCategoryPayload>('categories_reducer/create_category')
export const CREATE_CATEGORY_SUCCESS = createAction('categories_reducer/create_category_success')
export const CREATE_CATEGORY_FAIL = createAction('categories_reducer/create_category_fail')

interface UpdateCategoryPayload {
  id: number
  name: string
  priceRatio: number
}
export type UpdateCategoryActionType = PayloadAction<UpdateCategoryPayload>
export const UPDATE_CATEGORY = createAction<UpdateCategoryPayload>('categories_reducer/update_category')
export const UPDATE_CATEGORY_SUCCESS = createAction('categories_reducer/update_category_success')
export const UPDATE_CATEGORY_FAIL = createAction('categories_reducer/update_category_fail')

export const categoriesReducer = createReducer(initialState, (builder) => {
  builder.addCase(FETCH_CATEGORIES_SUCCESS, (state, action) => {
    return { ...state, categories: action.payload }
  })
})

export default categoriesReducer
