import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { PUT } from 'src/consts/methods'
import {
  UpdateCategoryActionType,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_FAIL,
  UPDATE_CATEGORY_SUCCESS
} from 'src/redux/categoriesReducer'
import { navigate } from 'src/helpers/navgate'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* UpdateCategoryFlow(action: UpdateCategoryActionType): Generator<any, void, any> {
  try {
    const params = { vehicle_category: { name: action.payload.name, price_ratio: action.payload.priceRatio } }
    yield call(apiCall, { endpoint: `/admin/vehicle_categories/${action.payload.id}`, method: PUT, params })
    yield put(UPDATE_CATEGORY_SUCCESS())
    navigate('/categories')
  } catch (error) {
    yield put(UPDATE_CATEGORY_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* UpdateCategorySaga(): Generator<any, void, any> {
  yield takeLatest(UPDATE_CATEGORY, UpdateCategoryFlow)
}
