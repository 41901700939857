import React, { useEffect, useState } from 'react'
import Layout from '../Layout/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { CREATE_CATEGORY, UPDATE_CATEGORY } from 'src/redux/categoriesReducer'
import { useParams } from 'react-router-dom'
import { RootReducer } from 'src/redux/rootReducer'
interface Props {
  type?: 'create' | 'edit'
}

const CategoryFormScreen = ({ type = 'create' }: Props): JSX.Element => {
  const { id } = useParams()

  const dispatch = useDispatch()
  const reducerCategory = useSelector((state: RootReducer) => {
    return state.categoriesReducer.categories.find((category) => category.id === Number(id)) ?? null
  })
  const [categoryName, setCategoryName] = useState('')
  const [priceRatio, setPriceRatio] = useState('')

  useEffect(() => {
    if (reducerCategory !== null) {
      setCategoryName(reducerCategory.name)
      setPriceRatio(reducerCategory.price_ratio.toString())
    }
  }, [reducerCategory])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (categoryName === '' || priceRatio === '') {
      return
    }
    if (type === 'create') {
      dispatch(CREATE_CATEGORY({ name: categoryName, priceRatio: Number(priceRatio) }))
    } else {
      dispatch(UPDATE_CATEGORY({ id: Number(id), name: categoryName, priceRatio: Number(priceRatio) }))
    }
  }

  return (
    <Layout>
      <div className="font-semibold text-16 p-3">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-primary text-xs font-bold mb-2" htmlFor="name">
              Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-primary text-xs leading-tight focus:outline-none focus:shadow-outline"
              id="categoryName"
              type="text"
              placeholder="Enter category name"
              value={categoryName}
              onChange={(e) => {
                setCategoryName(e.target.value)
              }}
            />
          </div>
          <div className="mb-4">
            <label className="block text-primary text-xs font-bold mb-2" htmlFor="priceRatio">
              Preisverhältnis
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-primary text-xs leading-tight focus:outline-none focus:shadow-outline"
              id="priceRatio"
              type="number"
              placeholder="Enter price ratio"
              value={priceRatio}
              onChange={(e) => {
                setPriceRatio(e.target.value)
              }}
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-orange hover:bg-accent-dark text-white font-bold text-xs py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit">
              {type === 'create' ? 'Einreichen' : 'Aktualisieren'}
            </button>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default CategoryFormScreen
