/* eslint-disable @typescript-eslint/ban-types */
import { createReducer, createAction, PayloadAction } from '@reduxjs/toolkit'
import { BatchInvoiceCustomer, InvoicePosition } from '../types/index'

export interface BatchInvoiceCustomerReducer extends BatchInvoiceCustomer {
  invoice_positions: InvoicePosition[]
  isLoading: boolean
}

const initialState: BatchInvoiceCustomerReducer = {
  id: 0,
  street_number: '',
  street: '',
  zip_code: '',
  company_name: '',
  city: '',
  tax_number: '',
  without_tax: false,
  invoice_positions: [],
  isLoading: false
}

interface UpdateBatchInvoiceCustomerPayload {
  id: number
  street: string
  company_name: string
  street_number: string
  zip_code: string
  city: string
  tax_number: string
  without_tax: boolean
}
export type UpdateBatchInvoiceCustomerActionType = PayloadAction<UpdateBatchInvoiceCustomerPayload>
export const UPDATE_BATCH_INVOICE_CUSTOMER = createAction<UpdateBatchInvoiceCustomerPayload>(
  'batch_invoice_customer_reducer/update_batch_invoice_customer'
)
export const UPDATE_BATCH_INVOICE_CUSTOMER_SUCCESS = createAction(
  'batch_invoice_customer_reducer/update_batch_invoice_customer_success'
)
export const UPDATE_BATCH_INVOICE_CUSTOMER_FAIL = createAction(
  'batch_invoice_customer_reducer/update_batch_invoice_customer_fail'
)

export interface CreateInvoicePositionPayload {
  price_gross: string
  zip_from: string
  zip_to: string
  company_name: string
  street: string
  street_number: string
  zip: string
  city: string
  description: string
  order_id: number
  order_number: string
  batch_invoice_customer_id: string
}
export type CreateInvoicePositionActionType = PayloadAction<CreateInvoicePositionPayload>
export const CREATE_INVOICE_POSITION = createAction<CreateInvoicePositionPayload>(
  'batch_invoice_customer_reducer/create_invoice_position'
)
export const CREATE_INVOICE_POSITION_SUCCESS = createAction(
  'batch_invoice_customer_reducer/create_invoice_position_success'
)
export const CREATE_INVOICE_POSITION_FAIL = createAction('batch_invoice_customer_reducer/create_invoice_position_fail')

interface FetchBachInvoiceCustomerPayload {
  id: number
}
export type FetchBatchInvoiceActionType = PayloadAction<FetchBachInvoiceCustomerPayload>
export const FETCH_BATCH_INVOICE_CUSTOMER = createAction<FetchBachInvoiceCustomerPayload>(
  'batch_invoice_customer_reducer/fetch_batch_invoice_customer'
)
export const FETCH_BATCH_INVOICE_CUSTOMER_SUCCESS = createAction<BatchInvoiceCustomer>(
  'batch_invoice_customer_reducer/fetch_batch_invoice_customer_success'
)
export const FETCH_BATCH_INVOICE_CUSTOMER_FAIL = createAction(
  'batch_invoice_customer_reducer/fetch_batch_invoice_customer_fail'
)

interface FetchInvoicePositionsPayload {
  customerId: number
}
export type FetchInvoicePositionsActionType = PayloadAction<FetchInvoicePositionsPayload>
export const FETCH_INVOICE_POSITIONS = createAction<FetchInvoicePositionsPayload>(
  'batch_invoice_customer_reducer/fetch_invoice_positions'
)
export const FETCH_INVOICE_POSITIONS_SUCCESS = createAction<InvoicePosition[]>(
  'batch_invoice_customer_reducer/fetch_invoice_positions_success'
)
export const FETCH_INVOICE_POSITIONS_FAIL = createAction('batch_invoice_customer_reducer/fetch_invoice_positions')

export interface CreateInvoiceFromInvoicePositions {
  invoicePositionIds: number[]
  batchInvoiceCustomerId: number
}
export type CreateInvoiceFromInvoicePositionActionType = PayloadAction<CreateInvoiceFromInvoicePositions>
export const CREATE_INVOICE_FROM_INVOICE_POSITIONS = createAction<CreateInvoiceFromInvoicePositions>(
  'batch_invoice_customer_reducer/create_invoice'
)
export const CREATE_INVOICE_FROM_INVOICE_POSITIONS_SUCCESS = createAction(
  'batch_invoice_customer_reducer/create_invoice_success'
)
export const CREATE_INVOICE_FROM_INVOICE_POSITIONS_FAIL = createAction(
  'batch_invoice_customer_reducer/create_invoice_fail'
)

export const batchInvoiceCustomerReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(FETCH_BATCH_INVOICE_CUSTOMER_SUCCESS, (state, action) => {
      return { ...state, ...action.payload }
    })
    .addCase(FETCH_INVOICE_POSITIONS_SUCCESS, (state, action) => {
      return { ...state, invoice_positions: action.payload }
    })
    .addCase(CREATE_INVOICE_FROM_INVOICE_POSITIONS, (state, action) => {
      return { ...state, isLoading: true }
    })
    .addCase(CREATE_INVOICE_FROM_INVOICE_POSITIONS_SUCCESS, (state, action) => {
      return { ...state, isLoading: false }
    })
    .addCase(CREATE_INVOICE_FROM_INVOICE_POSITIONS_FAIL, (state, action) => {
      return { ...state, isLoading: false }
    })
})

export default batchInvoiceCustomerReducer
