import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { POST } from 'src/consts/methods'
import {
  CreateCategoryActionType,
  CREATE_CATEGORY_FAIL,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY
} from 'src/redux/categoriesReducer'
import { navigate } from 'src/helpers/navgate'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* CreateCategoryFlow(action: CreateCategoryActionType): Generator<any, void, any> {
  try {
    const params = { vehicle_category: { name: action.payload.name, price_ratio: action.payload.priceRatio } }
    yield call(apiCall, { endpoint: '/admin/vehicle_categories', method: POST, params })
    yield put(CREATE_CATEGORY_SUCCESS())
    navigate('/categories')
  } catch (error) {
    yield put(CREATE_CATEGORY_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* CreateCategorySaga(): Generator<any, void, any> {
  yield takeLatest(CREATE_CATEGORY, CreateCategoryFlow)
}
