import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../Layout/Layout'
import { RootReducer } from 'src/redux/rootReducer'
import { FETCH_USERS, UPDATE_USER } from 'src/redux/usersReducer'
import { User } from 'src/types'
const EditUserScreen = (): JSX.Element => {
  const [role, setRole] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(FETCH_USERS())
  }, [dispatch])

  const { id } = useParams()
  const user = useSelector((state: RootReducer) => {
    const { users } = state.usersReducer
    return users.find((u) => u.id === Number(id)) ?? null
  })

  useEffect(() => {
    if (user !== null) {
      setRole(user.user_role)
      setEmail(user.email)
    }
  }, [user])

  return (
    <Layout withFilter>
      <div className="font-semibold text-22 p-3">
        <div>Ändere das Passwort vom name last name</div>
        <div className="h-3" />
        <label className="text-16 block">E-Mail-Addresse</label>
        <input
          className="border text-16 px-1"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
        />
        <div className="h-3" />
        <label className="text-16 block">Das neue Passwort</label>
        <input
          value={password}
          onChange={(e) => {
            setPassword(e.target.value)
          }}
          className="border text-16 px-1"
        />
        <div className="h-3" />

        <label className="text-16 block">Role</label>
        <select
          value={role}
          onChange={(e) => {
            setRole(e.target.value as User['user_role'])
          }}
          className="border text-16 px-1 w-[11.2rem]">
          <option value="super_admin">super_admin</option>
          <option value="admin">admin</option>
          <option value="user">user</option>
        </select>
        <div className="h-3" />
        <button
          onClick={() => {
            dispatch(UPDATE_USER({ id: user?.id ?? 0, email, password, user_role: role as User['user_role'] }))
          }}
          className="bg-orange text-14 py-2 px-10 text-center rounded-md cursor-pointer">
          Update
        </button>
      </div>
    </Layout>
  )
}

export default EditUserScreen
