import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { PUT } from 'src/consts/methods'
import { UPDATE_USER, UPDATE_USER_FAIL, UPDATE_USER_SUCCESS, UpdateUserActionType } from 'src/redux/usersReducer'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* UpdateUserFlow({ payload }: UpdateUserActionType): Generator<any, void, any> {
  try {
    yield call(apiCall, {
      endpoint: `/admin/users/${payload.id}`,
      method: PUT,
      params: { user_role: payload.user_role, email: payload.email, password: payload.password }
    })
    yield put(UPDATE_USER_SUCCESS())
  } catch (error) {
    yield put(UPDATE_USER_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* UpdateUserSaga(): Generator<any, void, any> {
  yield takeLatest(UPDATE_USER, UpdateUserFlow)
}
