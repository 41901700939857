/* eslint-disable @typescript-eslint/no-explicit-any */
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootReducer } from 'src/redux/rootReducer'
import { Point } from 'src/redux/routeReducer'

interface Props {
  item: Point
  dragHandleProps: any
}

const RouteElement = ({ item, dragHandleProps }: Props): JSX.Element => {
  const { phone, name, zip, bikeModel, distance, duration, transportType, orderNumber } = item

  const isDirectTransport = useSelector((state: RootReducer) => {
    return state.routeReducer.activePoints.filter((point) => point.orderNumber === orderNumber).length === 2
  })
  return (
    <div {...dragHandleProps} className="bg-white rounded-sm w-[20rem] border border-blue mb-1">
      <div style={{ backgroundColor: isDirectTransport ? '#ecf0f5' : 'white' }} className="p-3">
        <div className="flex flex-row justify-between">
          <p className="text-16 font-semibold">{bikeModel}</p>
          <p className="text-14 max-w-[9rem] text-right">
            {duration} {distance}
          </p>
        </div>
        <div className="h-[0.5rem]" />
        <p className="text-14 max-w-[9rem]">{transportType === 'start_place' ? 'ON' : 'EX'}</p>
        <div className="h-[0.5rem]" />
        <div className="flex flex-row justify-between">
          <div>
            <div>
              <FontAwesomeIcon size="xs" icon={faPhone} />
            </div>
            <p className="text-14 break-words word-break">{phone}</p>
          </div>
          <div>
            <div className="text-14 font-semibold">Name:</div>
            <p className="text-14 break-words word-break">{name}</p>
          </div>
          <div>
            <div className="text-14 font-semibold">Zip:</div>
            <p className="text-14">{zip}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RouteElement
