import React, { useEffect } from 'react'
import StatusSelect from '../atoms/StatusSelect'
import PriceInput from './PriceInput'
import TextAreaInput from './TextAreaInput'
import { useSelector, useDispatch } from 'react-redux'
import { RootReducer } from 'src/redux/rootReducer'
import { useParams } from 'react-router-dom'
import { paymentTypeMapper } from 'src/helpers/paymentTypeMapper'
import { SET_TITLE } from 'src/redux/headerReducer'

const OrderDataColumn = (): JSX.Element => {
  const { id: orderId } = useParams()
  const dispatch = useDispatch()
  const order = useSelector((state: RootReducer) => state.orderReducer.orders[orderId ?? ''])

  useEffect(() => {
    dispatch(SET_TITLE({ title: order?.order_number ?? '' }))
    return () => {
      dispatch(SET_TITLE({ title: '' }))
    }
  }, [dispatch, order])

  if (typeof order === 'undefined') {
    return <div>Loading...</div>
  }

  return (
    <div className="flex flex-row px-2 flex-1 max-w-[500px] min-w-[400px]">
      <div className="bg-white border-t-2 border-orange rounded-md p-4 flex-1">
        <div className="flex justify-between items-center">
          <label className="text-14">Beauftragt</label>
          <div className="w-5" />
          <PriceInput />
        </div>
        <div className="h-3" />
        <TextAreaInput label="Notiz" buttonLabel="Notiz senden" fieldName="note" />
        <div className="h-3" />
        <TextAreaInput label="Anmerkung zum status" buttonLabel="Senden" fieldName="status_note" />
        <div className="h-3" />
        <div className="border border-grayBackground rounded-sm">
          <div className="flex border-b border-grayBackground">
            <div className="flex-1 border-r border-grayBackground p-1 text-darkGrey px-2">Status</div>
            <div className="flex-1 p-1 px-2">
              <StatusSelect />
            </div>
          </div>
          <div className="flex border-b border-grayBackground">
            <div className="flex-1 border-r border-grayBackground p-1 text-darkGrey px-2">Vorname und Nachname</div>
            <div className="flex-1 p-1 px-2 text-darkGrey">{`${order.first_name} ${order.last_name}`}</div>
          </div>
          <div className="flex border-b border-grayBackground">
            <div className="flex-1 border-r border-grayBackground p-1 text-darkGrey px-2">Email</div>
            <div className="flex-1 p-1 px-2 text-darkGrey">{order.email}</div>
          </div>
          <div className="flex border-b border-grayBackground">
            <div className="flex-1 border-r border-grayBackground p-1 text-darkGrey px-2">Telefon</div>
            <div className="flex-1 p-1 px-2 text-darkGrey">
              <a className="text-blue" href={`https://wa.me/+49${order.phone}`}>
                {order.phone}
              </a>
            </div>
          </div>
          <div className="flex border-b border-grayBackground">
            <div className="flex-1 border-r border-grayBackground p-1 text-darkGrey px-2">Marke</div>
            <div className="flex-1 p-1 px-2 text-darkGrey">{order.mark}</div>
          </div>
          <div className="flex border-b border-grayBackground">
            <div className="flex-1 border-r border-grayBackground p-1 text-darkGrey px-2">Modell</div>
            <div className="flex-1 p-1 px-2 text-darkGrey">{order.bike_model}</div>
          </div>
          <div className="flex border-b border-grayBackground">
            <div className="flex-1 border-r border-grayBackground p-1 text-darkGrey px-2">Preis</div>
            <div className="flex-1 p-1 px-2 text-darkGrey">{order.price}</div>
          </div>
          <div className="flex border-b border-grayBackground">
            <div className="flex-1 border-r border-grayBackground p-1 text-darkGrey px-2">Erstellt von</div>
            <div className="flex-1 p-1 px-2 text-darkGrey">
              {order.created_by.charAt(0).toUpperCase() + order.created_by.slice(1)}
            </div>
          </div>
          <div className="flex border-b border-grayBackground">
            <div className="flex-1 border-r border-grayBackground p-1 text-darkGrey px-2">Zahlungsmethode</div>
            <div className="flex-1 p-1 px-2 text-darkGrey">{paymentTypeMapper(order.payment_type)}</div>
          </div>
          <div className="flex border-b border-grayBackground">
            <div className="flex-1 border-r border-grayBackground p-1 text-darkGrey px-2">Bezahlt</div>
            <div className="flex-1 p-1 px-2 text-darkGrey">{order.paid ? 'Wahr' : 'Falsch'}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderDataColumn
