import React, { useEffect, useState } from 'react'
import Layout from '../Layout/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { CREATE_BATCH_INVOICE_CUSTOMER } from 'src/redux/batchInvoiceCustomersReducer'
import { useParams } from 'react-router-dom'
import { RootReducer } from 'src/redux/rootReducer'
import { FETCH_BATCH_INVOICE_CUSTOMER, UPDATE_BATCH_INVOICE_CUSTOMER } from 'src/redux/batchInvoiceCustomerReducer'

interface Props {
  type: 'edit' | 'new'
}

const BatchCustomerForm = ({ type }: Props): JSX.Element => {
  const { id } = useParams()
  const [companyName, setCompanyName] = useState('')
  const [street, setStreet] = useState('')
  const [streetNumber, setStreetNumber] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [city, setCity] = useState('')
  const [taxNumber, setTaxNumber] = useState('')
  const [withoutTax, setWithoutTax] = useState(false)
  const dispatch = useDispatch()
  const reducerBatchCustomerData = useSelector((state: RootReducer) => state.batchInvoiceCustomerReducer)

  useEffect(() => {
    if (type === 'edit') {
      dispatch(FETCH_BATCH_INVOICE_CUSTOMER({ id: parseInt(id ?? '', 10) }))
    }
  }, [dispatch, id, type])

  useEffect(() => {
    if (type === 'edit') {
      setCity(reducerBatchCustomerData.city)
      setCompanyName(reducerBatchCustomerData.company_name)
      setStreet(reducerBatchCustomerData.street)
      setStreetNumber(reducerBatchCustomerData.street_number)
      setZipCode(reducerBatchCustomerData.zip_code)
      setTaxNumber(reducerBatchCustomerData.tax_number ?? '')
      setWithoutTax(reducerBatchCustomerData.without_tax ?? false)
    }
  }, [reducerBatchCustomerData, type])

  const handleButtonClick = (): void => {
    if (type === 'new') {
      dispatch(
        CREATE_BATCH_INVOICE_CUSTOMER({
          city,
          street,
          street_number: streetNumber,
          company_name: companyName,
          zip_code: zipCode,
          without_tax: withoutTax,
          tax_number: taxNumber
        })
      )
    } else {
      dispatch(
        UPDATE_BATCH_INVOICE_CUSTOMER({
          id: parseInt(id ?? '', 10),
          city,
          street,
          street_number: streetNumber,
          company_name: companyName,
          zip_code: zipCode,
          without_tax: withoutTax,
          tax_number: taxNumber
        })
      )
    }
  }

  return (
    <Layout withFilter>
      <div className="font-semibold text-22 p-3">
        <div>{type === 'new' ? 'Kunden erstellen' : 'Kunden bearbeiten'}</div>
        <div className="h-3" />
        <label className="text-16 block">Name der Firma</label>
        <input
          value={companyName}
          onChange={(e) => {
            setCompanyName(e.target.value)
          }}
          className="border text-16 px-1"
        />
        <div className="h-3" />
        <label className="text-16 block">Straße</label>
        <input
          value={street}
          onChange={(e) => {
            setStreet(e.target.value)
          }}
          className="border text-16 px-1"
        />
        <div className="h-3" />
        <label className="text-16 block">PLZ</label>
        <input
          value={zipCode}
          onChange={(e) => {
            setZipCode(e.target.value)
          }}
          className="border text-16 px-1"
        />
        <label className="text-16 block">Hausnummer</label>
        <input
          value={streetNumber}
          onChange={(e) => {
            setStreetNumber(e.target.value)
          }}
          className="border text-16 px-1"
        />
        <div className="h-3" />
        <label className="text-16 block">Stadt</label>
        <input
          value={city}
          onChange={(e) => {
            setCity(e.target.value)
          }}
          className="border text-16 px-1"
        />
        <div className="h-3" />
        <label className="text-16 block">Tax number</label>
        <input
          value={taxNumber}
          onChange={(e) => {
            setTaxNumber(e.target.value)
          }}
          className="border text-16 px-1"
        />
        <div className="h-3" />
        <label className="text-16 block">Without tax</label>
        <input
          type="checkbox"
          checked={withoutTax}
          onChange={(e) => {
            setWithoutTax(e.target.checked)
          }}
        />
        <div className="h-3" />
        <button
          onClick={handleButtonClick}
          className="bg-orange text-14 py-2 px-10 text-center rounded-md cursor-pointer">
          {type === 'new' ? 'Create' : 'Update'}
        </button>
      </div>
    </Layout>
  )
}

export default BatchCustomerForm
