import { call, put, takeLatest } from 'redux-saga/effects'
import apiCall from 'src/helpers/apiCall'
import { GET } from 'src/consts/methods'
import { FETCH_CATEGORIES, FETCH_CATEGORIES_FAIL, FETCH_CATEGORIES_SUCCESS } from 'src/redux/categoriesReducer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* FetchCategoriesFlow(): Generator<any, void, any> {
  try {
    const response = yield call(apiCall, { endpoint: '/admin/vehicle_categories', method: GET, params: {} })
    yield put(FETCH_CATEGORIES_SUCCESS(response))
  } catch (error) {
    yield put(FETCH_CATEGORIES_FAIL())
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* FetchCategoriesSaga(): Generator<any, void, any> {
  yield takeLatest(FETCH_CATEGORIES, FetchCategoriesFlow)
}
